.table_container {
    width: 100%;

    table {
        border-collapse: collapse;
        text-align: left;
        width: 100%;

        td, th {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            padding: 8px;
            font-family: 'Noto Sans SC', sans-serif;
            font-size: 14px;

            a {
                color: grey;
            }
        }

        td {
            .like-emoji {
                padding: 0;
                margin: 0;
                text-decoration: none;
            }

            .content {
                padding: 0;
                margin: 0
            }
        }

        th {
            color: grey;
            font-weight: 400;
        }

        .each-row:hover {
            background-color: #F5F5F5;
        }
    }

}