.search {
    width: 100%;
   
    input {
        width: 50%;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #D4D4D4;
        font-family: 'Noto Sans SC', sans-serif;
        border-radius: 4px;
    }

    input:focus {
        outline: none;
    }
}