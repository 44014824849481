.landing {
    background-image: linear-gradient(to bottom right, blue, purple) ;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .categories {
        width: 40%;
       

        @media (max-width: 800px){
            width: 60%;
        }

        .each_cate {
            background-color: white;
            margin-bottom: 30px;
            width: 100%;
            border-radius: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                font-size: 20px;
                margin-left: 20px;
            }

            .category_name {
                width: 100%;
                text-align: center;
            }

        }

        .each_cate:hover {
            background-color: #F9ED8D;
        }

    }
}