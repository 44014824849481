.share-container {
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    right: 0%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;

    .share {
        position: absolute;
        width: 40%;
        opacity: 1;
        border-color: #000; 
        border-style: groove; 
        border-width: 2px; 
        padding: 0px 30px 50px 30px; 
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: 1000px){
            width: 70%;
        }

        @media(max-width: 500px){
            width: 90%;
        }

        @media(max-width: 400px){
            width: 95%;
        }
        

        .share-btn {
            margin: 20px 0 0 10px;
            font-family: 'Noto Sans SC', sans-serif;
            width: 150px;
            border-radius: 4px;
            border: none;
            font-size: 18px;
            padding: 8px 12px 8px 12px;
            color: white;
            font-weight: bold;
            font-family: 'Noto Sans SC', sans-serif;
            background-image: linear-gradient(to bottom right, blue, purple) ;
        }

        .close {
            text-align: right;
            margin: 20px 0 10px 0;
            font-weight: bold;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .posted-message {
                color: red;
                font-style: italic;
            }
            
            .each-row {
                justify-content: flex-start;
                margin: 10px;

                .header {
                    margin-right: 10px;
                    font-size: 20px;
                    color: #3D30DA;

                }
    

                label {
                    margin-right: 10px;
                    font-size: 18px;
                    text-align: left;

                    @media(max-width: 500px){
                        font-size: 14px;
                    }
                  
                }

                select {
                    font-family: 'Noto Sans SC', sans-serif;
                }

                input {
                    width: 300px;
                    border: none;
                    font-size: 14px;
                    font-family: 'Noto Sans SC', sans-serif;

                    @media (max-width: 600px){
                        width: 200px;
                    }

                    @media (max-width: 400px){
                        width: 150px;
                    }
                }

                input:focus {
                    outline: none;
                    
                }

                .icon {
                    color: grey;

                    @media(max-width: 500px){
                        font-size: 14px;
                    }
                }
            }
        }

    }
}