.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #D4D4D4;
    height: 100vh;
    width: 200px;
    padding: 20px 0 0 0;

    .profile_image {
        border-radius: 50%;
        width: 40%;
    }

    .header {

    }

    .name {
        display: flex;
        
        .name-input {
            margin-top: 0;
            border: none;
            width: 80px;
            color: orange;
            font-size: 16px;
        }

        .name-input:focus {
            outline: none
        }

        .icon {
            color: grey;
            font-size: 14px;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 20px;

        .each-session {
            padding: 10px 0 10px 0;
            text-decoration: none;
            color: inherit;
            width: 100%;

            .icon {
                margin-right: 10px;
            }
        }

        .each-session:hover {
            background-color: #D5D5D6;
        }
    }
}