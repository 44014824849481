.nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #D4D4D4;
    font-family: 'Noto Sans SC', sans-serif;

    .logo {
        margin-left: 50px;
        color: #3D30DA;
    }

    .search-bar {
        width: 100%;
    }

    .share-btn {
        margin-right: 50px;
        font-family: 'Noto Sans SC', sans-serif;
        width: 150px;
        border-radius: 4px;
        border: none;
        font-size: 18px;
        padding: 8px 12px 8px 12px;
        color: white;
        font-weight: bold;
        font-family: 'Noto Sans SC', sans-serif;
        background-image: linear-gradient(to bottom right, blue, purple) ;
    }

    .share-btn:hover {
        background-image: linear-gradient(to bottom right, blue, red) ;
        border: none;
    }
}